// react-router-dom components
import { Link } from "react-router-dom";
import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
// import Checkbox from "@mui/material/Checkbox";
import Grid from '@mui/material/Grid'
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import FormHelperText from "@mui/material/FormHelperText";
import MDAlert from "components/MDAlert";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

import AuthService from '../../../../services/auth.service';

function Partners() {
	
	const [signupDetails,setDetails] = useState({
		firstname : '',
		lastname : '',
		mobile: '',
		password : '',
		email:'',
		confirmpassword:'',
	});
	const [error, setError] = useState(false);
	const [mobileerror, setmobileError] = useState(false);
	const [passworderror, setPasswordError] = useState(false);
	const [emailerror, setEmailError] = useState(false);
	const [terms,setTerms] = useState(false);
	const [form,setForm] = useState(true);
	const [message, setMessage] = useState("");

	const handleTerms = (event) => {
		const newValue = event.target.checked;
		setTerms(newValue);
		
		if (!newValue) {
			setForm(true); // Immediately disable button if terms unchecked
		} else {
			// Only check other validations if terms are accepted
			checkform();
		}
	};

	const handleChange = (e) =>{
		const {name,value} = e.target;
		setDetails((prev) => {
			return {...prev,[name]:value}
		})
		if(name === 'mobile'){
			const inputPhoneNumber = value;
			let formattedPhoneNumber = inputPhoneNumber.replace(/\D/g, ''); // remove all non-numeric characters
			if (formattedPhoneNumber.length >= 3 && formattedPhoneNumber.length < 6) {
			  formattedPhoneNumber = `(${formattedPhoneNumber.slice(0, 3)}) ${formattedPhoneNumber.slice(3)}`;
			} else if (formattedPhoneNumber.length >= 6) {
			  formattedPhoneNumber = `(${formattedPhoneNumber.slice(0, 3)}) ${formattedPhoneNumber.slice(3, 6)}-${formattedPhoneNumber.slice(6, 10)}`;
			}
			setDetails((prev) => {
				return {...prev,[name]:formattedPhoneNumber}
			})
			const mobileNumberRegex = /^[1-9]\d{9}$/;
			const usmobile = /^(\([0-9]{3}\)\s*|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			(mobileNumberRegex.test(value) || usmobile.test(value)) ? setmobileError(false): setmobileError(true);
		} else if(name === 'email'){
			const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w)*$/;
			emailRegex.test(value) || value.length === 0 ? setEmailError(false): setEmailError(true);
		} else if(name === 'confirmpassword'){
			setPasswordError(value !== signupDetails.password);
		} else if(name === 'password'){
			setPasswordError(value !== signupDetails.confirmpassword);
		}
		// Call checkform after state updates
		setTimeout(() => checkform(), 0);
	}

	const checkform = () => {
		const hasEmail = signupDetails.email.length > 0;
		const hasMobile = signupDetails.mobile.length > 0;
		const hasPassword = signupDetails.password.length > 0;
		const hasConfirmPassword = signupDetails.confirmpassword.length > 0;
		const passwordsMatch = signupDetails.password === signupDetails.confirmpassword;
		
		const isValid = !emailerror && 
			!passworderror && 
			!mobileerror && 
			hasEmail && 
			hasMobile && 
			hasPassword && 
			hasConfirmPassword &&
			passwordsMatch &&
			terms; // Must have terms checked
		
		console.log('Form validation:', {
			emailerror,
			passworderror,
			mobileerror,
			hasEmail,
			hasMobile,
			hasPassword,
			hasConfirmPassword,
			passwordsMatch,
			terms,
			isValid,
			email: signupDetails.email,
			mobile: signupDetails.mobile,
			password: signupDetails.password,
			confirmpassword: signupDetails.confirmpassword
		});
		
		setForm(!isValid);
	}

	const handleRegister = (e) => {
		e.preventDefault();
		setError(false);
		signupDetails.vendor_type = "Agent";
		signupDetails.mra = terms;
		signupDetails.cell_phone = signupDetails.mobile;
		signupDetails.phone = signupDetails.mobile;

		setMessage("");
		AuthService.register(signupDetails).then(
			(response) => {
				const user = response.data.response.user;
				window.location.href = "https://boomtownroi.formstack.com/forms/partners?field153655178-first="+user.first_name+"&field153655178-last="+user.last_name+"&field153655189-first="+user.first_name+"&field153655189-last="+user.last_name+"&Email="+user.username+"&GUID="+user.guid;
			},(error) => {
				setError(true);
		    const resMessage =error.response.data.response.error
		    setMessage(resMessage);
			}
		);
	};

	return (
		<CoverLayout image="/Partners-maf-logo.svg" logoWidth = "304px" logoHeight = "78px">
			<Card>
				<MDBox
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="success"
					mx={2}
					mt={-3}
					p={3}
					mb={1}
					textAlign="center"
					sx={{
						background: `linear-gradient(180deg, #838DF1 0%, #6471ED 100%);`
					}}
				>

					<MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
						Register
					</MDTypography>
					<MDTypography display="block" variant="body2" color="white" my={1}>
						Set up Your account and continue to onboarding
					</MDTypography>
				</MDBox>
				{error && (<MDAlert color="error" dismissible>{message}</MDAlert>)}
				<MDBox pt={4} pb={3} px={3}>
					<MDBox component="form" role="form">
						<Grid container spacing={3} pt={3}>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="text" name="firstname" label="First Name"  fullWidth  onChange={handleChange} />
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="text" name="lastname" label="Last Name"  fullWidth  onChange={handleChange}/>
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput error={mobileerror} name="mobile" helperText="your mobile number without country code" placeholder="mobile" type="text" label="Cell Phone"  value={signupDetails.mobile} fullWidth onChange={handleChange} />
									{mobileerror && <FormHelperText error>Please enter a valid phone number</FormHelperText>}
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="email" error={emailerror} name="email" label="Email Address"  fullWidth  onChange={handleChange}/>
									{emailerror && <FormHelperText error>Please enter a valid email address</FormHelperText>}
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="password" name="password" label="Password"  fullWidth  onChange={handleChange}/>
								</MDBox>
							</Grid>
							<Grid item xs={12} lg={6} md={6}>
								<MDBox>
									<MDInput type="password" error={passworderror} name = "confirmpassword" label="Confirm Password"  fullWidth  onChange={handleChange}/>
									{passworderror && <FormHelperText error>Your passwords have to match</FormHelperText>}
								</MDBox>
							</Grid>
						</Grid>

						<MDBox alignItems="center" ml={1} mt={4}>
							<input
								type="checkbox"
								checked={terms}
								onChange={handleTerms}
								style={{
									width: '18px',
									height: '18px',
									marginRight: '8px',
									cursor: 'pointer'
								}}
							/>
							<MDTypography
								variant="button"
								fontWeight="regular"
								color="text"
								sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
								display="inline"
							>
								&nbsp;&nbsp;I have read and agree to MyAgentFinder&apos;s&nbsp;
							</MDTypography>
							<MDTypography
								component="a"
								href="https://www.myagentfinder.com/terms"
								variant="button"
								fontWeight="bold"
								color="info"
								textGradient
								display="inline"
								target="_blank"
							>
								Terms of Service
							</MDTypography>
							<MDTypography
								component="a"
								href="https://www.myagentfinder.com/privacy"
								variant="button"
								fontWeight="bold"
								color="info"
								textGradient
								display="inline"
								target="_blank"
							>
								Privacy Policy
							</MDTypography>
							<MDTypography
								component="a"
								href="https://www.myagentfinder.com/electronic-communications-consent"
								variant="button"
								fontWeight="bold"
								color="info"
								textGradient
								display="inline"
								target="_blank"
							>
								Consent to the Use of Electronic Communications
							</MDTypography>
						</MDBox>
						<MDBox mt={4} mb={1}>
							<MDButton variant="gradient" color="solidPrimary" disabled={!terms || form} fullWidth onClick={handleRegister}>
								Get Started
							</MDButton>
						</MDBox>
						<MDBox mt={3} mb={1} textAlign="center">
							<MDTypography variant="button" color="text">
								Already have an account?{" "}
								<MDTypography
									component={Link}
									to="/"
									variant="button"
									color="info"
									fontWeight="medium"
									textGradient
								>
									Sign In
								</MDTypography>
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
			</Card>
		</CoverLayout>
	);
}

export default Partners;
